export const existingPolicyResultStatements = [
    'Over de kosten van het oversluiten van uw verzekering maakt u afspraken met uw adviseur. Met deze kosten is in de berekening geen rekening gehouden.',
    'Indien er sprake is van een annuïtaire of lineaire kapitaalverloop is er in de berekening van uw lopende verzekering vanuit gegaan dat u de laatste 5 jaar geen premie meer betaalt.',
    'Voor het afsluiten van een nieuwe verzekering wordt uw gezondheid beoordeeld. Het is daarom belangrijk dat u uw oude verzekering niet beëindigd voordat u geaccepteerd bent voor uw nieuwe verzekering.',
    'Als uw lopende verzekering verpand is aan een geldlening dan is een goedkeuring nodig van uw geldverstrekker om de oude verzekering te beëindigen en de nieuwe verzekering te verpanden. Hieraan kunnen kosten verbonden zijn.',
    'Aan deze berekening kunt u geen rechten ontlenen. De premie is indicatief en onder voorbehoud van (medische) acceptatie.'
];

export const newPolicyResultStatements = [
    'Over de kosten van het afsluiten van uw verzekering maakt u afspraken met uw adviseur. Met deze kosten is in de berekening geen rekening gehouden.',
    'Aan deze berekening kunt u geen rechten ontlenen. De premie is indicatief en onder voorbehoud van (medische) acceptatie.'
];

export const MODE = {
    NEW: 'new',
    CALCULATE_SUM_ASSURED: 'calculateSumAssuredAtStartDate',
    CALCULATE_ORV_PREMIUMS: 'calculateOrvPremiums',
    EXISTING_POLICY: 'existing',
}

export const TypeOfCoverEnum = {
    LEVEL_COVER: 'LEVEL_COVER',
    ANNUITY_DECREASING: 'ANNUITY_DECREASING',
    STRAIGHTLINE_DECREASING: 'STRAIGHTLINE_DECREASING'
}

export const TwoInsuredPersonsInPolicy = 1;
