"use strict";
// TODO: Bundle all the things!!

$(document).ready(function() {
    $(".tooltip").tooltipster({
        animation: "fade",
        delay: 200,
        maxWidth: 400,
        side: "right",
        trigger: "click"
    }), /*$(".form-group label").append($("#error").clone(!0).html()),*/ $(window).scroll(function() {
        $(this).scrollTop() > 400 ? $("#back-to-top").fadeIn() : $("#back-to-top").fadeOut()
    }), $("#back-to-top").click(function() {
        return $("html, body").animate({
            scrollTop: 0
        }, 600), !1
    }), $("#submit").click(function () {
        return $("html, body").animate({
            scrollTop: $("#results").position().top
        }, 600)
    }), $("select").find("option:not(:first)").addClass("active"), $("select").on("change", function() {
        $(this).attr("class", "").addClass($(this).children(":selected").attr("class"))
    });
});