export function setOrder(policyTerms) {
    let insurers = {
        QL_ORV_2020_01: 1,
        QLS_ORV_2020_01: 5,
        CL_ORV_07_2019: 4,
        CLS_ORV_07_2019: 8,
        IPTS_ORV_07_2019: 6,
        IPT_ORV_07_2019: 2,
        ACE_ORV_2020_03: 3,
        ACES_ORV_2020_03: 7,
    };

    return insurers[policyTerms];
}

export function sortByOrder(processedPremiums) {
    let key = Object.keys(processedPremiums)
        .sort((key1, key2) => {
            if (processedPremiums[key1].order < processedPremiums[key2].order) return -1;
            else if (processedPremiums[key1] > processedPremiums[key2]) return 1;
            else return 0;
        });

    let sortedProcessedPremiums = {};

    key.forEach(key => sortedProcessedPremiums[key] = processedPremiums[key])

    return sortedProcessedPremiums;
}
