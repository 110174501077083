angular.module('orvApp').directive('currency', ['$filter', function ($filter) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elem, attrs, ctrl) {
      function parseAsDutchStandard(value) {
        if (value === null) return;

        if (typeof value === 'string') {
          // value = value.split(',')[0];
          value = value.replace('.', '');
          value = value.replace(',', '.');
        }
        
        var numberOfDecimals = 0;
        if (attrs.currencyDecimals) {
          numberOfDecimals = 2;
        }

        return $filter('currency')(value, '', numberOfDecimals).trim();
      }

      function parseForModel(value) {
        if (value === null) return;
        
        if (typeof value === 'string') {
          // value = value.split(',')[0];
          value = value.replace('.', '');
          value = value.replace(',', '.');
        }

        // var numberOfDecimals = 0;
        // if (attrs.currencyDecimals) {
        //   numberOfDecimals = 2;
        // }

        // var parsed = $filter('currency')(value, '', 0).trim();

        return parseFloat(value, 10);
      }

      function round (amount) {
        return Number(Math.round(amount +'e'+ 0) +'e-'+ 0).toFixed(0);
      }

      ctrl.$formatters.push(parseAsDutchStandard);
      ctrl.$parsers.push(parseForModel);

      elem.bind('blur', function () {
        var parsedValue = parseAsDutchStandard(elem.val());
        elem.val(parsedValue);
      });
    }
  };
}]);