angular.module('orvApp').directive('percentage', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, elem, attrs, ctrl) {
      function parsePerDutchStandard (value) {
        if (typeof value === 'string' && value.indexOf(',') > -1) {
          value = value.replace(',', '.');
        }

        var parsedNumber = decimals(value);

        parsedNumber = parsedNumber.replace('.', ',');

        return parsedNumber;
      }

      function parseForModel(value) {
        value = value.replace(',', '.');

        return decimals(value);
      }

      function decimals (amount) {
        return Number(Math.round(amount +'e'+ 2) +'e-'+ 2).toFixed(2);
      }

      ctrl.$formatters.push(parsePerDutchStandard);
      ctrl.$parsers.push(parseForModel);
      
      elem.bind('blur', function () {
        var parsedValue = parsePerDutchStandard(elem.val());
        elem.val(parsedValue);
      });
    }
  };
});