angular.module('orvApp').directive('minmax', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      min: '&minmaxMin',
      max: '&minmaxMax',
      disabled: '&?minmaxDisabled'
    },
    link: function (scope, elem, attrs, ngModel) {
      ngModel.$validators.minmax = function(modelValue) {
        if (scope.disabled && scope.disabled()) return true;
        
        return modelValue >= scope.min() && modelValue <= scope.max()
      }
    }
  }
});