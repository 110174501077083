import OrvError from "../../errors/OrvError";
import orvValidator from "../../validators/OrvValidator";
import { TwoInsuredPersonsInPolicy, TypeOfCoverEnum } from "../../enums";
import {setOrder, sortByOrder} from "./utils";
import moment from "moment";

var angular = require('angular');

angular.module('orvApp').service('CalculationService', ['$resource', function($resource) {
  function _calculateOrvPremiums(userData, opts) {
    const apiPref = process.env.ANGULAR_APP_API_PREF || '';
    const API_URL = `${apiPref}/premiumcalculator/v2/calculations/calculate/by-product-type`;

    const endpoint = $resource(API_URL);

    return endpoint.save(userData).$promise.then(function (response) {
      let processedPremiums = {};

      response.toJSON().results.forEach(result => {
        processedPremiums[`${result.policyTerms}`] = {
          monthly: result.premiumProgression[0].amount,
          yearly: result.totalPremium,
          premiumEndDate: moment(result.premiumProgression[result.premiumProgression.length-1].endDate).format('DD-MM-YYYY'),
          order: setOrder(result.policyTerms),
        };
      });

      return sortByOrder(processedPremiums);
    });
  }

//TODO Replace with PC calculations when it will be exist  !!!
function _calculateSumAssured(userData) {
  if(!orvValidator.isValidaUserData(userData)) {
    throw new OrvError(orvValidator.error.message, orvValidator.error.code);
  }
    let joint = userData.TweeLevens;
    let typeOfCover = userData.Verloop;
    let sumAssured1 = userData.VerzekerdKapitaal1;
    let dateOfCommencement = userData.IngangsDatum;
    let dateOfCommencementOld = userData.IngangsDatumOud;
    let dateOfEndOld = userData.EindDatumOud;

    let sumAssured2 = 0;

    if (joint === TwoInsuredPersonsInPolicy) {
      sumAssured2 = userData.VerzekerdKapitaal2;
    }

    let annuityRate = 0;
    if (typeOfCover === TypeOfCoverEnum.ANNUITY_DECREASING) {
      annuityRate = userData.Rente;
    }

    let [, monthOfDateOfCommencement, yearOfDateOfCommencement] = dateOfCommencement.split("-");
    let [, monthOfOldDateOfCommencement, yearOfOldDateOfCommencement] = dateOfCommencementOld.split("-");
    let [, monthOfOldDateOfEnd, yearOfOldDateOfEnd] = dateOfEndOld.split("-");

    monthOfDateOfCommencement = parseInt(monthOfDateOfCommencement, 10);
    yearOfDateOfCommencement = parseInt(yearOfDateOfCommencement, 10);

    monthOfOldDateOfCommencement = parseInt(monthOfOldDateOfCommencement, 10);
    yearOfOldDateOfCommencement = parseInt(yearOfOldDateOfCommencement, 10);

    monthOfOldDateOfEnd = parseInt(monthOfOldDateOfEnd, 10);
    yearOfOldDateOfEnd = parseInt(yearOfOldDateOfEnd, 10);

    const startSumAssured1 = sumAssured1;
    const startSumAssured2 = sumAssured2;

    let termInMonths;
    if (monthOfOldDateOfCommencement == monthOfOldDateOfEnd) {
      termInMonths = (yearOfOldDateOfEnd - yearOfOldDateOfCommencement) * 12;
    } else {
      termInMonths = (yearOfOldDateOfEnd - yearOfOldDateOfCommencement) * 12;
      termInMonths += (monthOfOldDateOfEnd - monthOfOldDateOfCommencement);
    }

    let prevSumAssured1 = startSumAssured1;
    let prevSumAssured2;

    if (joint === TwoInsuredPersonsInPolicy) {
      prevSumAssured2 = startSumAssured2;
    }
    let currentYear = yearOfOldDateOfCommencement;
    let currentMonth = monthOfOldDateOfCommencement;

    let annuityPerMonth;

    if (typeOfCover === TypeOfCoverEnum.ANNUITY_DECREASING) {
      annuityPerMonth = Math.pow((1 + (annuityRate / 100)), (1 / 12)) - 1;
    }

    let newSumAssured1;
    let newSumAssured2;
    if (typeOfCover === TypeOfCoverEnum.ANNUITY_DECREASING || typeOfCover === TypeOfCoverEnum.STRAIGHTLINE_DECREASING) {
      for (let monthcount = 1; monthcount < termInMonths; monthcount++) {

        if (typeOfCover === TypeOfCoverEnum.ANNUITY_DECREASING) {
          newSumAssured1 = Math.max(prevSumAssured1 * (1 + (annuityPerMonth)) - startSumAssured1 / ((1 - Math.pow((1 + (annuityPerMonth)), -termInMonths)) / annuityPerMonth), 0);

          if (joint === TwoInsuredPersonsInPolicy) {
            newSumAssured2 = Math.max(prevSumAssured2 * (1 + (annuityPerMonth)) - startSumAssured2 / ((1 - Math.pow((1 + (annuityPerMonth)), -termInMonths)) / annuityPerMonth), 0);
          }
        } else if (typeOfCover === TypeOfCoverEnum.STRAIGHTLINE_DECREASING) {
          newSumAssured1 = Math.max(0, prevSumAssured1 - (startSumAssured1 / termInMonths));
          if (joint === TwoInsuredPersonsInPolicy) {
            newSumAssured2 = Math.max(0, prevSumAssured2 - (startSumAssured2 / termInMonths));
          }
        }

        currentMonth += 1;
        if (currentMonth > 12) {
          currentMonth = 1;
          currentYear += 1;
        }

        if (currentMonth === monthOfDateOfCommencement && currentYear === yearOfDateOfCommencement) {
          break;
        } else {
          prevSumAssured1 = newSumAssured1;
          if (joint === TwoInsuredPersonsInPolicy) {
            prevSumAssured2 = newSumAssured2;
          }
        }
      }
    } else {
      newSumAssured1 = sumAssured1;
      if (joint === TwoInsuredPersonsInPolicy) {
        newSumAssured2 = sumAssured2;
      }
    }

    let commonSumAssured = {};
    commonSumAssured.newSumAssured1 = Math.round(newSumAssured1);
    if (joint === TwoInsuredPersonsInPolicy) {
      commonSumAssured.newSumAssured2 = Math.round(newSumAssured2);
    }

    return commonSumAssured;
}


  return {
    calculateOrvPremiums: _calculateOrvPremiums,
    calculateSumAssured: _calculateSumAssured,
  };
}]);
