var angular = require('angular');

angular.module('orvApp', ['ngResource', 'ngMessages', 'moment-picker'])
  .run(function () {
    // Hide second insurant div when the page is loaded but Angular is not done yet.
    // This prevents FOUT.
    var hiddenSecondInsurant = document.querySelector('.hide-second-insurant');

    if (hiddenSecondInsurant) {
      hiddenSecondInsurant.classList.remove('hide-second-insurant');
    }
  })
  .config(['momentPickerProvider', function (momentPickerProvider) {
    momentPickerProvider.options({
      locale: 'nl',
      format: 'DD-MM-YYYY'
    });
  }]);