function OrvError(message, code) {
    this.message = message;
    this.code = code;
}
OrvError.prototype = Object.create(Error.prototype);
OrvError.prototype.constructor = OrvError;

export default OrvError;

export const OrvErrorsEnum = {
    ERROR_CODE_1: 'De keuze voor 1 of 2 verzekerden is ongeldig',
    ERROR_CODE_2: 'Kapitaal verloop niet opgegeven of bevat een ongeldige waarde',
    ERROR_CODE_4: 'Verzekerd bedrag van de 1e verzekerde niet opgegeven of bevat een ongeldige waarde',
    ERROR_CODE_12: 'De ingangsdatum is niet opgegeven of is geen geldige datum',
    ERROR_CODE_43: 'De oude ingangsdatum is niet opgegeven of is geen geldige datum',
    ERROR_CODE_44: 'De oude einddatum is niet opgegeven of is geen geldige datum',
    ERROR_CODE_5: 'Verzekerd bedrag van de 2e verzekerde niet opgegeven of bevat een ongeldige waarde',
    ERROR_CODE_8: 'Rente percentage niet opgegeven of bevat een ongeldige waarde'
};
