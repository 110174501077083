import { OrvErrorsEnum } from "../errors/OrvError";
import { TypeOfCoverEnum } from "../enums";

let OrvValidator = (function () {
    let _currentError;

    function _isValidaUserData(userData) {

        if(userData.TweeLevens === undefined || userData.TweeLevens === null){
            _currentError.message = OrvErrorsEnum.ERROR_CODE_1;
            _currentError.code = 1;
            return false;
        }
        if(userData.Verloop === undefined || userData.Verloop === null){
            _currentError.message = OrvErrorsEnum.ERROR_CODE_2;
            _currentError.code = 2;
            return false;
        }
        if(userData.VerzekerdKapitaal1 === undefined || userData.VerzekerdKapitaal1 === null){
            _currentError.message = OrvErrorsEnum.ERROR_CODE_4;
            _currentError.code = 4;
            return false;
        }
        if(userData.IngangsDatum === undefined || userData.IngangsDatum === null){
            _currentError.message = OrvErrorsEnum.ERROR_CODE_12;
            _currentError.code = 12;
            return false;
        }
        if(userData.IngangsDatumOud === undefined || userData.IngangsDatumOud === null){
            _currentError.message = OrvErrorsEnum.ERROR_CODE_43;
            _currentError.code = 43;
            return false;
        }
        if(userData.EindDatumOud === undefined || userData.EindDatumOud === null){
            _currentError.message = OrvErrorsEnum.ERROR_CODE_44;
            _currentError.code = 44;
            return false;
        }
        if(userData.TweeLevens === 1
            && (userData.VerzekerdKapitaal2 === undefined || userData.VerzekerdKapitaal2 === null))
        {
            _currentError.message = OrvErrorsEnum.ERROR_CODE_5;
            _currentError.code = 5;
            return false;
        }
        if(userData.typeOfCover === TypeOfCoverEnum.ANNUITY_DECREASING
            && (userData.Rente === undefined || userData.Rente === null))
        {
            _currentError.message = OrvErrorsEnum.ERROR_CODE_8;
            _currentError.code = 8;
            return false;
        }

        return true;
    }

    return {
        isValidaUserData: _isValidaUserData,
        error: _currentError
    };
})();

export default OrvValidator;
