import { getErrorsMessages } from "../../utils";
import {
  existingPolicyResultStatements,
  MODE,
  newPolicyResultStatements,
  TypeOfCoverEnum
} from "../../enums";

var angular = require('angular');
import moment from 'moment';

angular.module('orvApp').controller('orvFormController', ['CalculationService', function (CalculationService) {
  var vm = this;

  vm.copyrightInfo = {
    year: moment().format('YYYY')
  };

  vm.startDateRanges = {
    min: {
      raw: moment().add(1, 'month').date(1),
      formatted: moment().add(1, 'month').date(1).format('DD-MM-YYYY')
    },
    max: {
      raw: moment().add(3, 'months').date(1),
      formatted: moment().add(3, 'months').date(1).format('DD-MM-YYYY')
    }
  };

  vm.birthDateRanges = {
    max: {
      raw: moment(vm.startDateRanges.min.raw).subtract(18, 'years'),
    },
    min: {
      raw: moment(vm.startDateRanges.min.raw).subtract(75, 'years'),
    }
  };
  vm.birthDateRanges.max.formatted = vm.birthDateRanges.max.raw.format('DD-MM-YYYY');
  vm.birthDateRanges.min.formatted = vm.birthDateRanges.min.raw.format('DD-MM-YYYY');

  vm.formData = {
    transferIndication: {
      mode: 'new',
      paymentMethod: 'monthly',
      existingDurationEndDate: undefined,
      existingDurationStartDate: undefined,
      currentPremium: null,
      calculatedInsuredAmount: [0],
      oldInsuredAmount1: null,
      oldInsuredAmount2: null
    },
    secondInsurant: false,
    insurants: [{
      dob: '',
      isSmoker: false,
      insuredAmount: null
    }, {
      dob: '',
      isSmoker: false,
      insuredAmount: null
    }],
    insuredAmountType: '',
    interest: 1.00,
    duration: '',
    startDate: vm.startDateRanges.min.raw
  };

  vm.insuredAmountTypes = [
    { name: 'gelijkblijvend', typeOfCover: TypeOfCoverEnum.LEVEL_COVER },
    { name: 'annuïtair dalend', typeOfCover: TypeOfCoverEnum.ANNUITY_DECREASING },
    { name: 'lineair dalend', typeOfCover: TypeOfCoverEnum.STRAIGHTLINE_DECREASING }
  ];

  // TODO: Come up with a more durable 'state' mechanism (or a viable alternative)
  vm.resetStates = function() {
    vm.toggles = {
      results: false,
      spinner: false,
      calculatedInsuredAmountSpinner: false,
      resultsSuccess: false,
      resultsError: false
    };

    vm.results = {
      success: [],
      errors: {
        code: 0,
        message: ''
      }
    };
  };
  vm.resetStates();

  vm.checkForErrors = function (inputName) {
    return this.orvForm[inputName].$invalid && !this.orvForm[inputName].$pristine;
  };

  vm.onSubmit = function () {
    vm.resetStates();

    vm.orvForm.$setPristine();

    if (!this.orvForm.$valid) {
      var counter = 0;

      angular.forEach(this.orvForm, function (value, key) {
        if (typeof value === 'object' && value.hasOwnProperty('$modelValue') && value.$pristine) {
          if (!vm.formData.secondInsurant && value.$name.substr(0, 2) === 'v2') {
            return;
          }

          value.$setDirty();
          counter++;
        }
      });

      if (counter > 0) {
        vm.results.errors.message = 'Niet alle velden zijn (correct) ingevuld.';
        vm.toggles.resultsError = true;
        vm.toggles.results = true;
      }

      return;
    }

    vm.calculateInsuredAmount({ calculatePremiums: true });
  };

  vm.calculateOldInsuredAmount = function () {
    if (vm.formData.transferIndication.mode === MODE.NEW) return;

    if (vm.formData.secondInsurant && !vm.formData.transferIndication.oldInsuredAmount2) return;

    vm.formData.transferIndication.calculatedInsuredAmount = null;
    vm.calculateInsuredAmount({ spinner: 'calculatedInsuredAmountSpinner' });
  };

  vm.getCurrentMonthPremium = function () {
    var months = 1;
    var monthPremium;

    switch (vm.formData.transferIndication.paymentMethod) {
      case 'monthly':
        months = 1;
        break;
      case 'quarterly':
        months = 3;
        break;
      case 'half-yearly':
        months = 6;
        break;
      case 'yearly':
        months = 12;
        break;
    }

    monthPremium = vm.formData.transferIndication.currentPremium / months;

    return monthPremium;

  };

  vm.calculateInsuredAmount = function (opts) {
    var spinner = opts.spinner ? opts.spinner : 'spinner';
    vm.toggles[spinner] = true;

    var parsedFormData = vm.parseFormData(vm.formData);
    if (opts.calculatePremiums) {

      vm.formData.insurants.forEach((insurant, index) => {
        if (index === 1 && !vm.formData.secondInsurant) return;

        var insurantID = index + 1;

        if (parsedFormData.Mode === MODE.CALCULATE_SUM_ASSURED) {
          insurant.insuredAmount = vm.formData.transferIndication.calculatedInsuredAmount[index]
        }

        parsedFormData['VerzekerdKapitaal' + insurantID] = insurant.insuredAmount;
      });

      parsedFormData.Mode = MODE.CALCULATE_ORV_PREMIUMS;

      if (parsedFormData.EindDatumOud) {
        parsedFormData.EindDatum = moment(parsedFormData.EindDatumOud, 'DD-MM-YYYY').date(1).format('DD-MM-YYYY');
        delete parsedFormData.EindDatumOud;
      }
    }

    if (parsedFormData.Mode === MODE.CALCULATE_SUM_ASSURED) {
      try {
        const commonSumAssured = CalculationService.calculateSumAssured(parsedFormData);

        vm.formData.transferIndication.calculatedInsuredAmount = [];

        Object.keys(commonSumAssured).forEach(function (sumAssured) {
          vm.formData.transferIndication.calculatedInsuredAmount.push(commonSumAssured[sumAssured]);
        });
      } catch (error) {
        vm.results.errors.code = error.code;
        vm.results.errors.message = error.message;

        vm.toggles.resultsError = true;
        vm.toggles.results = true;
      } finally {
        vm.toggles[spinner] = false;
      }
    } else if (parsedFormData.Mode === MODE.CALCULATE_ORV_PREMIUMS) {

      CalculationService.calculateOrvPremiums(vm.createRequestEntity(parsedFormData), {duration: vm.formData.duration})
          .then(function (successResponse) {
            if (vm.formData.transferIndication.mode === MODE.EXISTING_POLICY) {
              var tempLowestPremium = {
                insurer: Object.keys(successResponse)[0],
                total: successResponse[Object.keys(successResponse)[0]].yearly
              };

              Object.keys(successResponse).forEach(function (insurer, index) {
                if (successResponse[insurer].yearly < tempLowestPremium.total) {
                  tempLowestPremium.insurer = insurer;
                  tempLowestPremium.total = successResponse[insurer].yearly;
                }
              });

              vm.results.success = {
                new: {
                  monthlyPremium: vm.formatAmountDecimals(successResponse[tempLowestPremium.insurer].monthly),
                  yearlyPremium: vm.formatAmountDecimals(successResponse[tempLowestPremium.insurer].yearly)
                }
              };

              var endDate = parsedFormData.EindDatum || parsedFormData.EindDatumOud;
              var duration = moment(endDate, 'DD-MM-YYYY').diff(moment(parsedFormData.IngangsDatum, 'DD-MM-YYYY'), 'months');
              if (vm.formData.insuredAmountType !== TypeOfCoverEnum.LEVEL_COVER) {
                if (duration > 60) {
                  duration = duration - 60;
                } else {
                  duration = 0;
                }
              }

              vm.results.success.old = {
                monthlyPremium: vm.formatAmountDecimals(vm.getCurrentMonthPremium()),
                yearlyPremium: vm.formatAmountDecimals(vm.getCurrentMonthPremium() * duration)
              };

              var monthlyDifference = Math.max(vm.results.success.old.monthlyPremium - vm.results.success.new.monthlyPremium, 0);
              var yearlyDifference = Math.max(vm.results.success.old.yearlyPremium - vm.results.success.new.yearlyPremium, 0);

              vm.results.success.difference = {
                monthlyDifferenceFormatted: vm.formatAmountDecimals(monthlyDifference),
                yearlyDifferenceFormatted: vm.formatAmountDecimals(yearlyDifference),
                monthlyDifferenceRaw: monthlyDifference,
                yearlyDifferenceRaw: yearlyDifference
              };

              vm.results.statements = existingPolicyResultStatements;
            } else {
              const insurers = new Map();
              insurers.set('QL', 'Quantum Leben');
              insurers.set('QLS', 'Quantum Leben Special');
              insurers.set('CL', 'Credit Life');
              insurers.set('CLS', 'Credit Life Special');
              insurers.set('IPTS', 'iptiQ Special');
              insurers.set('IPT', 'iptiQ');
              insurers.set('CH', 'Chubb Life');
              insurers.set('CHS', 'Chubb Life Special');
              insurers.set('BNP', 'BNP');
              insurers.set('BNPS', 'BNP Special');

              vm.results.success = [];

              Object.keys(successResponse).forEach(policyTerm => {
                const insurer = insurers.get(policyTerm.split('_')[0]);

                if (!insurer) {
                  console.error(`Insurer not found in mapping for policy term ${policyTerm}`);
                }

                var result = {
                  insurer,
                  monthlyPremium: vm.formatAmountDecimals(successResponse[policyTerm].monthly),
                  yearlyPremium: vm.formatAmountDecimals(successResponse[policyTerm].yearly),
                  premiumEndDate: successResponse[policyTerm].premiumEndDate,
                };

                vm.results.success.push(result);

                vm.results.statements = newPolicyResultStatements;
              });
            }

            vm.toggles.resultsSuccess = true;
            vm.toggles.results = true;
          })
          .catch(function (errorResponse) {
              const errors = getErrorsMessages(errorResponse);

              vm.results.errors.code = errorResponse.data.errorCode;
              vm.results.errors.message = errors.join();

              vm.toggles.resultsError = true;
              vm.toggles.results = true;
          })
          .finally(function () {
            vm.toggles[spinner] = false;
          });
    }
  };

  vm.formatAmountDecimals = function(amount) {
    // https://stackoverflow.com/questions/6134039/format-number-to-always-show-2-decimal-places/34796988#34796988
    return Number(Math.round(amount +'e'+ 2) +'e-'+ 2).toFixed(2);
  }

  vm.parseFormData = function () {
    var parsed = {
      TweeLevens: vm.formData.secondInsurant ? 1 : 0,
      Verloop: vm.formData.insuredAmountType,
      Betaling: 1,
      IngangsDatum: vm.formData.startDate.format('DD-MM-YYYY'),
      Mode: vm.formData.transferIndication.mode === MODE.NEW ? MODE.CALCULATE_ORV_PREMIUMS : MODE.CALCULATE_SUM_ASSURED
    };

    if (parsed.Verloop === TypeOfCoverEnum.ANNUITY_DECREASING) {
      parsed.Rente = vm.formatAmountDecimals(vm.formData.interest);
    }

    if (parsed.Mode === MODE.CALCULATE_SUM_ASSURED) {
      parsed.IngangsDatumOud = vm.formData.transferIndication.existingDurationStartDate.format('DD-MM-YYYY');
      parsed.EindDatumOud = vm.formData.transferIndication.existingDurationEndDate.format('DD-MM-YYYY');
    } else {
      parsed.EindDatum = moment(vm.formData.startDate).add(vm.formData.duration, 'years').format('DD-MM-YYYY');
    }

    vm.formData.insurants.forEach(function (insurant, index) {
      if (index === 1 && !vm.formData.secondInsurant) return;

      var insurantID = index + 1;

      parsed['Geboortedatum' + insurantID] = insurant.dob.format('DD-MM-YYYY');
      parsed['Roker' + insurantID] = insurant.isSmoker;

      var insuredAmount = parsed.Mode === MODE.CALCULATE_SUM_ASSURED ? vm.formData.transferIndication['oldInsuredAmount' + insurantID] : insurant.insuredAmount;

      parsed['VerzekerdKapitaal' + insurantID] = insuredAmount;
    });

    return parsed;
  };

  vm.createRequestEntity = function (parsedFormData) {
    let body = {
      type: "CalculationORV",
      productType: "ORV",
      productVariant: "QLS",
      dateOfCommencement: vm.formData.startDate.format('YYYY-MM-DD'),
      paymentMethod: "MONTHLY",
      surrenderValue: false,
      premiumSplit: false,
      premiumExemptionDisabilityIncluded: false,
      accidentsIncluded: false,
      deceasedChildrenIncluded: false,
      insured1Cover: {
        insuredAttributes: {}
      },
      insured2Cover: vm.formData.secondInsurant ? {
        insuredAttributes: {}
      } : null
    }

    vm.formData.insurants.forEach((insurant, index) => {
      if (index === 1 && !vm.formData.secondInsurant) return;

      const currentInsured = `insured${index+1}Cover`;

      if (vm.formData.insuredAmountType !== TypeOfCoverEnum.LEVEL_COVER) {
        body[currentInsured].sumAssuredEnd = 0;
      }

      if (vm.formData.insuredAmountType === TypeOfCoverEnum.ANNUITY_DECREASING) {
        body[currentInsured].annuityRate = vm.formatAmountDecimals(vm.formData.interest);
      }

      body[currentInsured].typeOfCover = vm.formData.insuredAmountType;
      body[currentInsured].insuredAttributes.dateOfBirth = insurant.dob.format('YYYY-MM-DD');
      body[currentInsured].insuredAttributes.isSmoker = insurant.isSmoker;
      body[currentInsured].startDate = vm.formData.startDate.format('YYYY-MM-DD');
      if (vm.formData.transferIndication.mode === MODE.EXISTING_POLICY) {
        body[currentInsured].endDate = moment(parsedFormData.EindDatum, 'DD-MM-YYYY').format('YYYY-MM-DD');
      } else {
        body[currentInsured].endDate = moment(vm.formData.startDate, 'DD-MM-YYYY').add(vm.formData.duration, 'years').format('YYYY-MM-DD');
      }
      body[currentInsured].sumAssured = insurant.insuredAmount;
    });

    return body;
  }

  vm.validateStartDate = function (newDate, oldDate) {
    if (!newDate) return;

    if (newDate.date() > 1) {
      vm.orvForm.startdate.$setValidity('dayOutOfBounds', false);
      return;
    }

    vm.orvForm.startdate.$setValidity('dayOutOfBounds', true);

    vm.calculateOldInsuredAmount();
  };

}]);
